import React from "react";
import Select from "../../../Select";
import { FilterMainComponentProps } from "../Filter";

const priorityOptions = [
  { label: "All", value: "" },
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
];

const PrioritySelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => (
  <Select
    value={values}
    // @ts-ignore
    options={priorityOptions}
    // @ts-ignore
    onChange={(e: { value: string; label: string }) =>
      onChange(e?.value || null)
    }
    isClearable={values !== ""}
    placeholder={placeholder}
  />
);

export default PrioritySelect;
